<template>
    <div class="footer-social">
        <ul>
            <li
                v-for="socialLink in socialLinks"
                :key="socialLink.id">
                <a
                    :href="socialLink.link"
                    :aria-label="socialLink.title"
                    target="_blank">
                    <i :class="['fa', socialLink.icon]"></i>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>

import socialLinksMixin from '../../../../mixins/socialLinksMixin'

export default {
  name: 'SocialsComponent',
  mixins: [
    socialLinksMixin
  ]
}
</script>
