export default {
  data () {
    return {
      errorFields: [],
      submitErrors: {}
    }
  },
  methods: {
    catchError (error) {
      this.submitErrors = this.$utils.getErrs(error)
      this.errorFields = (typeof this.submitErrors === 'object')
        ? Object.keys(this.submitErrors)
        : []

      this.validateRecaptcha()
    },
    validateRecaptcha () {
      if (
        !Array.isArray(this.errorFields) ||
                !this.errorFields.includes('recaptchaToken')
      ) {
        return
      }

      const errorMessage = String(this.submitErrors.recaptchaToken)
      this.$toast.error(errorMessage)
    }
  }
}
