<template>
    <div class="parallax-box">
        <div class="item">
            <img
                class="w-100 full-visible"
                :src="getImagePath('about-us/who-we-are-bg.jpeg')"
                alt="Who we are ?"/>
        </div>
    </div>
</template>

<script>

import assetsMixin from '../../../../mixins/assetsMixin'

export default {
  name: 'SingleImage',
  mixins: [
    assetsMixin
  ]
}
</script>
