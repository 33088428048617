<template>
    <div class="wrapper">
        <loader-component/>
        <div id="main">
            <header-component/>
            <router-view/>
            <footer-component/>
        </div>
    </div>
</template>

<script>

import LoaderComponent from './../layouts/default-partials/LoaderComponent'
import HeaderComponent from './../layouts/default-partials/header/HeaderComponent'
import FooterComponent from './../layouts/default-partials/footer/FooterComponent'

export default {
  name: 'DefaultLayout',
  components: {
    FooterComponent,
    HeaderComponent,
    LoaderComponent
  }
}
</script>
