<template>
    <div class="row">
        <div class="col-md-12">
            <h2 class="section-title section-title--technologies dec-title">
                <span>Our <strong> Technologies</strong></span>
            </h2>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <our-skills-list/>
        </div>
    </div>
</template>

<script>
import OurSkillsList from './OurSkillsList'

export default {
  name: 'OurSkillsDescription',
  components: {
    OurSkillsList
  }
}
</script>
