<template>
    <div class="row">
        <div class="col-md-8">
            <h2 class="section-title">What <strong>We Do </strong></h2>
            <p>
                To ensure that we provide top-notch solutions that surpass our clients' expectations, we adhere
                to a clearly defined process. Our process comprises the following stages:
            </p>
        </div>
    </div>
</template>

<script>
export default {
  name: 'WhatWeDoDescription'
}
</script>
