<template>
    <div class="row">
        <div class="col-md-12">
            <div class="technologies-list">
                <div class="row d-flex flex-wrap">
                    <our-skills-item
                        v-for="skillItem in skillsList"
                        :key="skillItem.id"
                        :title="skillItem.title"
                        :image-path="skillItem.imagePath"
                        :wrapper-class="skillItem.class"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import OurSkillsItem from './OurSkillsItem'
import assetsMixin from '../../../../mixins/assetsMixin'

export default {
  name: 'OurSkillsList',
  components: {
    OurSkillsItem
  },
  mixins: [
    assetsMixin
  ],
  data () {
    return {
      skillsList: [
        {
          id: 1,
          title: 'PHP',
          imagePath: this.getImagePath('technologies/php.png'),
          class: 'technologies-item col-xs-6 col-sm-4 col-md-2'
        },
        {
          id: 2,
          title: 'Laravel',
          imagePath: this.getImagePath('technologies/laravel_v2.png'),
          class: 'technologies-item col-xs-6 col-sm-4 col-md-2'
        },
        {
          id: 3,
          title: 'VueJs',
          imagePath: this.getImagePath('technologies/vue.png'),
          class: 'technologies-item col-xs-6 col-sm-4 col-md-2'
        },
        {
          id: 4,
          title: 'TypeScript',
          imagePath: this.getImagePath('technologies/typescript.png'),
          class: 'technologies-item col-xs-6 col-sm-4 col-md-2'
        },
        {
          id: 5,
          title: 'JavaScript',
          imagePath: this.getImagePath('technologies/js.png'),
          class: 'technologies-item col-xs-6 col-sm-4 col-md-2'
        },
        {
          id: 6,
          title: 'Docker',
          imagePath: this.getImagePath('technologies/docker.png'),
          class: 'technologies-item col-xs-6 col-sm-4 col-md-2'
        },
        {
          id: 7,
          title: 'Redis',
          imagePath: this.getImagePath('technologies/redis.png'),
          class: 'technologies-item col-xs-6 col-sm-4 col-md-2'
        },
        {
          id: 8,
          title: 'MongoDB',
          imagePath: this.getImagePath('technologies/mongo.png'),
          class: 'technologies-item col-xs-6 col-sm-4 col-md-2'
        },
        {
          id: 9,
          title: 'RabbitMQ',
          imagePath: this.getImagePath('technologies/rabbit.png'),
          class: 'technologies-item col-xs-6 col-sm-4 col-md-2'
        },
        {
          id: 10,
          title: 'Jenkins',
          imagePath: this.getImagePath('technologies/jenkins.png'),
          class: 'technologies-item col-xs-6 col-sm-4 col-md-2'
        }
      ]
    }
  }
}
</script>
