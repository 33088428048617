<template>
    <div class="fog-wrapper">
        <div id="foglayer_01" class="fog">
            <div class="image01"></div>
            <div class="image02"></div>
        </div>
        <div id="foglayer_02" class="fog">
            <div class="image01"></div>
            <div class="image02"></div>
        </div>
        <div id="foglayer_03" class="fog">
            <div class="image01"></div>
            <div class="image02"></div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'FogComponent'
}
</script>
