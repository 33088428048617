<template>
    <div class="content dark-bg">

        <section-number-component
            wrapper-class="sect-subtitle interest-facts-subtitle left-align-small"
            :section-number=sectionNumber
            translate-y="150"
        />

        <parallax-image-component
            wrapper-class="bg"
            :data-bg="getImagePath('interesting-facts/interesting-facts-bg.jpg')"
            translate-y="300"
        />

        <section>
            <div class="container">
                <facts-description/>
            </div>
        </section>
    </div>
</template>

<script>

import FactsDescription from './interesting-facts-section/FactsDescription'
import SectionNumberComponent from './../../../components/common/SectionNumberComponent'
import ParallaxImageComponent from './../../../components/common/ParallaxImageComponent'
import assetsMixin from '../../../mixins/assetsMixin'

export default {
  name: 'InterestingFactsSection',
  props: {
    sectionNumber: {
      required: false,
      type: String,
      default: '00'
    }
  },
  components: {
    ParallaxImageComponent,
    SectionNumberComponent,
    FactsDescription
  },
  mixins: [
    assetsMixin
  ]
}
</script>
