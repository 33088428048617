<template>

    <height-emulator-component/>

    <footer class="content-footer">
        <div class="container">
            <div class="row d-flex flex-wrap">
                <div class="col-xs-12 col-sm-12 col-md-6">
                    <footer-logo-component/>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 d-flex align-items-end">
                    <copyright-component/>
                </div>
            </div>
        </div>

        <particular-animation-component/>

    </footer>
</template>

<script>

import ParticularAnimationComponent from './content-footer/ParticularAnimationComponent'
import CopyrightComponent from './content-footer/CopyrightComponent'
import FooterLogoComponent from './content-footer/FooterLogoComponent'
import HeightEmulatorComponent from './content-footer/HeightEmulatorComponent'

export default {
  name: 'ContentFooterComponent',
  components: {
    HeightEmulatorComponent,
    FooterLogoComponent,
    CopyrightComponent,
    ParticularAnimationComponent
  }
}
</script>
