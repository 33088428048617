<template>
    <div class="row">
        <div class="col-md-8">
            <h2 class="section-title">
                <span>How we make <strong> IT work</strong></span>
            </h2>
            <p>
                We love to work in Agile iterations, building applications block by block, minimizing costs, and
                regularly evaluating technical and business decisions in a working environment.
            </p>
        </div>
    </div>
</template>

<script>

export default {
  name: 'HowWeMakeWorkDescription'
}
</script>
