<template>
    <div class="content about-us-section" id="secAboutUs">
        <section>
            <section-number-component
                wrapper-class="sect-subtitle right-align-dec"
                :section-number=sectionNumber
                translate-y="200"
            />

            <div class="container">
                <div class="row">
                    <div class="col-md-4 single-image-block">
                        <single-image/>
                    </div>
                    <div class="col-md-8">
                        <about-us-description/>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

import AboutUsDescription from './about-us-section/AboutUsDescription'
import SingleImage from './about-us-section/SingleImage'
import SectionNumberComponent from './../../../components/common/SectionNumberComponent'

export default {
  name: 'AboutUsSection',
  props: {
    sectionNumber: {
      required: false,
      type: String,
      default: '00'
    }
  },
  components: {
    SectionNumberComponent,
    SingleImage,
    AboutUsDescription
  }
}
</script>
