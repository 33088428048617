<template>
  <component :is="layout">
    <router-view></router-view>
  </component>
</template>

<script>

import DefaultLayout from './../layouts/DefaultLayout'

export default {
  name: 'App',
  computed: {
    layout () {
      return this.$route.meta.layout
        ? this.$route.meta.layout
        : DefaultLayout
    }
  }
}
</script>
