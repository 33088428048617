<template>
    <div class="footer-item footer-logo">
        <a href="#" class="ajax">
            <img src="/images/footer_logo.png" alt="Amplire">
        </a>
        <p>Amplire offers you the opportunity to become one of the most digitally successful companies in your industry
            in quantum leaps </p>
    </div>
</template>

<script>
export default {
  name: 'FooterLogoComponent'
}
</script>
