export default {
  getCsrfToken () {
    const csrfAttribute = document.querySelector('meta[name="csrf-token"]')
    if (typeof csrfAttribute === 'undefined' || !csrfAttribute) {
      return ''
    }

    return csrfAttribute.getAttribute('content')
  }
}
