<template>
    <div class="footer-wrap">
                <span class="copyright">
                    ©AMPLIRE&nbsp;{{ currentYear }}. All rights reserved.
                </span>
        <span class="to-top">To Top</span>
    </div>
</template>

<script>
export default {
  name: 'CopyrightComponent',
  data () {
    return {
      currentYear: new Date().getFullYear()
    }
  }
}
</script>
