<template>
    <div class="services-holder">
        <what-we-do-preview-item
            v-for="whatWeDoItem in whatWeDoList"
            :key="whatWeDoItem.id"
            :title="whatWeDoItem.title"
            :id="whatWeDoItem.id"
            :image-src="whatWeDoItem.imageSrc"
        />
    </div>
    <div class="row">
        <div class="col-md-6">
            <!-- serv-post  -->
            <div class="serv-post">
                <what-we-do-consultation/>
                <what-we-do-planning/>
                <what-we-do-development/>
                <what-we-do-deployment/>
            </div>
            <!-- serv-post end-->
        </div>
        <div class="col-md-6"></div>
    </div>
</template>

<script>

import WhatWeDoPreviewItem from './WhatWeDoPreviewItem'
import WhatWeDoConsultation from './details-items/WhatWeDoConsultation'
import WhatWeDoPlanning from './details-items/WhatWeDoPlanning'
import WhatWeDoDevelopment from './details-items/WhatWeDoDevelopment'
import WhatWeDoDeployment from './details-items/WhatWeDoDeployment'
import assetsMixin from '../../../../mixins/assetsMixin'

export default {
  name: 'WhatWeDoList',
  components: {
    WhatWeDoConsultation,
    WhatWeDoPlanning,
    WhatWeDoDevelopment,
    WhatWeDoDeployment,
    WhatWeDoPreviewItem
  },
  mixins: [
    assetsMixin
  ],
  data () {
    return {
      whatWeDoList: [
        {
          title: 'Consultation',
          imageSrc: this.getImagePath('what-we-do/consultation.jpg'),
          id: 'whatWeDoConsultation'
        },
        {
          title: 'Planning',
          imageSrc: this.getImagePath('what-we-do/planning.jpg'),
          id: 'whatWeDoPlanning'
        },
        {
          title: 'Development',
          imageSrc: this.getImagePath('what-we-do/development.jpg'),
          id: 'whatWeDoDevelopment'
        },
        {
          title: 'Deployment',
          imageSrc: this.getImagePath('what-we-do/deployment.jpg'),
          id: 'whatWeDoDeployment'
        }
      ]
    }
  }
}
</script>
