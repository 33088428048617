<template>
    <div>
        <!-- section title  -->
        <h2 class="section-title about-us-title">Welcome we <strong> are Amplire</strong></h2>
        <!-- section title  end -->
        <p>
            Our team consists of experienced and knowledgeable professionals who are committed to offering our clients
            advanced and reliable technology solutions.
        </p>
        <p>
            Our fundamental belief is that our success is directly linked to the success of our clients. Therefore, we
            make endless efforts to understand their clear requirements and develop customized solutions that meet and
            exceed their expectations. Our focus is on developing long-term relationships with our clients and helping
            them achieve their goals through the power of technology.
        </p>
        <p>
            We realize that one of our clients' most valuable resources is time. That's why all our work is customized
            so that the client gets the expected results in the optimal time frame. And here are our main principles of
            work:
        </p>
        <ul class="about-us-list">
            <li>
                <span>
                    continuous training and improvement of our technical skills
                </span>
            </li>
            <li>
                <span>
                    understanding the needs and problems of the client
                </span>
            </li>
            <li>
                <span>
                    accurate setting of tasks and deadlines for projects
                </span>
            </li>
            <li>
                <span>
                    effective time management of the whole team
                </span>
            </li>
            <li>
                <span>
                    high-quality communication within and outside the team.
                </span>
            </li>
        </ul>
        <p>
            We are extremely proud of our work and enthusiastically support our clients in achieving their goals. Our
            goal is to create an environment of openness and collaboration where our clients can work closely with us to
            achieve their goals. Whether you are a small business in need of IT assistance or a large corporation in
            need of enterprise-level solutions, we are here to help you.
        </p>
        <a
            href="#secContacts"
            class="custom-scroll-link btn anim-button fl-l about-us-contact-btn">
            <span>Contact Us</span>
            <i class="fa fa-long-arrow-right"></i>
        </a>
    </div>
</template>

<script>
export default {
  name: 'AboutUsDescription'
}
</script>
