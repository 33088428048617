<template>
    <section class="not-found">
        <div class="not-found__left">
            <img
                src="/images/404/not-found.svg"
                alt="Not Found">
        </div>
        <div class="not-found__right">
            <h1 class="text-error-title">404</h1>
            <p class="text-error-subtitle mb-6">Whoops…</p>
            <p class="text-error-message">
                We're lost in space. The page you were trying to view does not exist.
            </p>
            <a
                href="/"
                class="btn anim-button">
                <span>Back Home</span>
                <i class="fa fa-long-arrow-right"></i>
            </a>
        </div>
    </section>
</template>

<script>
export default {
  name: 'NotFoundPage'
}
</script>
