<template>
    <div class="content dark-bg overflow-hidden">

        <section-number-component
            wrapper-class="sect-subtitle left-align-dec"
            :section-number=sectionNumber
            translate-y="200"
        />

        <parallax-image-component
            wrapper-class="bg"
            :data-bg="getImagePath('testimonials/testimonials-bg.jpg')"
            translate-y="300"
        />

        <section>
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <h2 class="section-title">Testimonials</h2>
                    </div>
                    <div class="col-md-8">
                        <testimonials-list/>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

import SectionNumberComponent from './../../../components/common/SectionNumberComponent'
import ParallaxImageComponent from './../../../components/common/ParallaxImageComponent'
import TestimonialsList from './testimonials-section/TestimonialsList'
import assetsMixin from '../../../mixins/assetsMixin'

export default {
  name: 'TestimonialsSection',
  props: {
    sectionNumber: {
      required: false,
      type: String,
      default: '00'
    }
  },
  components: {
    TestimonialsList,
    ParallaxImageComponent,
    SectionNumberComponent
  },
  mixins: [
    assetsMixin
  ]
}
</script>
