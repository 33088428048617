import './bootstrap'

import { createApp } from 'vue'

import router from './routes'
import utilsPlugin from './plugins/utils'
import App from './views/App.vue'
import toasterPlugin from '@incuca/vue3-toaster'
import { VueReCaptcha as reCaptchaPlugin } from 'vue-recaptcha-v3'

const app = createApp(App)

app
  .use(router)
  .use(utilsPlugin)
  .use(toasterPlugin, {
    position: 'top-right'
  })
  .use(reCaptchaPlugin, { siteKey: process.env.MIX_GOOGLE_RECAPTCHA_SITE_KEY })
  .mount('#app')
