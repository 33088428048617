<template>
    <div class="hero-wrap-item center-item">
        <h2>Amplify your business with our IT solutions</h2>
        <h3>Amplire offers you the opportunity to become one of the most digitally successful companies in your industry in
            quantum leaps</h3>
    </div>
</template>

<script>
export default {
  name: 'VideoTitleSection'
}
</script>
