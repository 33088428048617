<template>
    <div id="whatWeDoDevelopment" class="serv-details">
        <h3>Development/Testing</h3>
        <p>
            The development and testing phases are critical in ensuring the successful delivery of high-quality software
            solutions. Here are some additional details about our development and testing processes:
        </p>
        <ol class="ser-list">
            <li>
                <strong>Development:</strong> During the development phase, our team of experienced developers works
                closely with our
                clients to build custom software solutions that meet their needs and objectives. We follow a structured
                and agile approach to software development, which allows us to deliver high-quality solutions quickly
                and efficiently.
            </li>
            <li>
                <strong>Testing:</strong> During the testing phase, we conduct thorough testing to ensure that the
                software solution meets all of the client's requirements and objectives. Our testing process includes
                the following steps
            </li>
        </ol>
    </div>
</template>

<script>
export default {
  name: 'WhatWeDoDevelopment'
}
</script>
