<template>
    <div id="whatWeDoPlanning" class="serv-details">
        <h3>Planning</h3>
        <p>
            The planning phase is crucial in ensuring the successful execution of the project. It is during this phase
            that we take the insights gained from the consultation phase and develop a comprehensive plan that outlines
            the scope of the project, timelines, and budget. Here are some additional details about our planning
            process:
        </p>
        <ol class="ser-list">
            <li>
                <strong>Scope Definition:</strong> We start by defining the scope of the project. This involves
                identifying the project's
                goals, deliverables, and requirements. We work closely with our clients to ensure that we capture all of
                their needs and requirements and define the project's scope accordingly.
            </li>
            <li>
                <strong>Timelines:</strong> Once the scope is defined, we develop a detailed timeline for the project.
                This timeline
                outlines the major milestones and deadlines for each phase of the project. We also identify any
                dependencies or risks that could impact the project's timeline.
            </li>
            <li>
                <strong> Budget:</strong> We work closely with our clients to develop a detailed budget for the project.
                This includes
                estimating the costs associated with software development, hardware, licensing, and any other expenses
                that may arise during the project. We also identify any potential cost-saving opportunities to ensure
                that the project remains within budget.
            </li>
            <li>
                <strong>Resource Planning:</strong> We identify the resources needed for the project, including team
                members, technology,
                and equipment. We ensure that we have the right mix of skills and expertise to execute the project
                successfully.
            </li>
            <li>
                <strong>Risk Management:</strong> We identify any potential risks that could impact the project's
                success and develop
                strategies to mitigate these risks. We also develop a contingency plan to ensure that we are prepared to
                address any issues that may arise during the project.
            </li>
            <li>
                <strong>Communication Plan:</strong> We establish a communication plan to ensure that everyone involved
                in the project is
                kept up-to-date on progress and any changes to the plan. This includes regular project status updates,
                progress reports, and meetings.
            </li>
        </ol>
    </div>
</template>

<script>
export default {
  name: 'WhatWeDoPlanning'
}
</script>
