<template>
    <div class="loader">
        <div id="movingBallG">
            <div class="movingBallLineG"></div>
            <div id="movingBallG_1" class="movingBallG"></div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'LoaderComponent'
}
</script>
