<template>
    <div :class="wrapperClass">
        <img
            :src=imagePath
            :alt=title
        />
        <div class="special-line special-line--left-border"></div>
        <div class="special-line special-line--right-border"></div>
        <div class="special-line special-line--top-border"></div>
        <div class="special-line special-line--bottom-border"></div>
    </div>
</template>

<script>
export default {
  name: 'OurSkillsItem',
  props: {
    title: {
      required: true,
      type: String
    },
    wrapperClass: {
      required: false,
      type: String
    },
    imagePath: {
      required: true,
      type: String
    }
  }
}
</script>
