<template>
    <div class="row">
        <div class="col-md-4">
            <h2 class="section-title">Our <strong> Experience</strong></h2>
        </div>
        <div class="col-md-8">
            <p>
                Our company provides a wide range of services aimed at helping businesses harness the potential of
                technology to achieve their goals. Our services are customized to meet the individual requirements of
                each client, and we work closely with them to ensure that they get the most out of our offerings.
            </p>
            <p>
                We don’t depend on borders or scale in our business, so we work with companies around the whole world
                and provide enterprise-level solutions to both small businesses and large corporations.
            </p>
            <p>
                Our successfully implemented cases:
            </p>
            <ul class="our-experience-list">
                <li>
                    <span>
                        innovative startups - Sweden, Finland
                    </span>
                </li>
                <li>
                    <span>
                        health care projects - Israel, Canada, and the USA
                    </span>
                </li>
                <li>
                    <span>
                        fintech projects - Germany
                    </span>
                </li>
                <li>
                    <span>
                        blockchain and cryptocurrency - the EU
                    </span>
                </li>
                <li>
                    <span>
                        hotel business - Italy
                    </span>
                </li>
                <li>
                    <span>
                        public services and utilities - Ukraine
                    </span>
                </li>
                <li>
                    <span>
                        e-commerce projects: exclusive jewelry store - Ireland, branded clothing - the EU, designer lighting systems - the United States; equipment for asphalt compaction and asphalt products - the United States, Canada, etc.
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
  name: 'FactsDescription'
}
</script>
