<template>
    <div
        :class="wrapperClass"
        :data-top-bottom="dataTopBottom"
        :data-bottom-top="dataBottomTop">
        <span>{{ sectionNumber }}</span>
    </div>
</template>

<script>
export default {
  name: 'SectionNumberComponent',
  props: {
    wrapperClass: {
      required: false,
      type: String
    },
    sectionNumber: {
      required: true,
      type: String
    },
    translateY: {
      required: true,
      type: [
        Number,
        String
      ]
    }
  },
  computed: {
    dataTopBottom () {
      return 'transform: translateY(-' + this.translateY + 'px);'
    },
    dataBottomTop () {
      return 'transform: translateY(' + this.translateY + 'px);'
    }
  }
}
</script>
