<template>
    <!-- content-->
    <div class="content" id="secOurWork">
        <section>
            <section-number-component
                wrapper-class="sect-subtitle right-align-dec"
                :section-number=sectionNumber
                translate-y="-400"
            />

            <div class="container">

                <how-we-make-work-description/>

                <div class="row">
                    <div class="col-md-12">
                        <how-we-make-work-content/>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <!-- content end -->
</template>

<script>

import SectionNumberComponent from '../../../components/common/SectionNumberComponent'
import HowWeMakeWorkDescription from './how-we-make-work-section/HowWeMakeWorkDescription'
import HowWeMakeWorkContent from './how-we-make-work-section/HowWeMakeWorkContent'

export default {
  name: 'HowWeMakeWorkSection',
  components: {
    HowWeMakeWorkDescription,
    HowWeMakeWorkContent,
    SectionNumberComponent
  },
  props: {
    sectionNumber: {
      required: false,
      type: String,
      default: '00'
    }
  }
}
</script>
