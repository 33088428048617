<template>
    <div class="logo-holder">
        <a href="#">
            <img src="/images/logo.png" alt="Amplire"/>
        </a>
    </div>
</template>

<script>
export default {
  name: 'LogoComponent'
}
</script>
