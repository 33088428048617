<template>
    <div class="nav-button-holder">
        <div class="nav-button vis-m">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
</template>

<script>
export default {
  name: 'MobileNavButton'
}
</script>
