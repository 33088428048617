<template>
    <div class="how-we-make-work-content">
        <div class="how-we-make-work-timeline">
            <how-we-make-work-timeline/>
        </div>
        <div class="how-we-make-work-processes">
            <ul class="how-we-make-work-processes__list">
                <li
                    v-for="processStep in processSteps"
                    :key="processStep.id"
                    class="how-we-make-work-processes__item">
                    <img
                        class="how-we-make-work-processes__item-img"
                        :src=processStep.imgSrc
                        :alt=processStep.title>

                    <p class="how-we-make-work-processes__item-title">
                        {{ processStep.title }}
                    </p>
                    <p class="how-we-make-work-processes__item-description">
                        {{ processStep.description }}
                    </p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

import HowWeMakeWorkTimeline from './HowWeMakeWorkTimeline'
import assetsMixin from '../../../../mixins/assetsMixin'

export default {
  name: 'HowWeMakeWorkContent',
  components: {
    HowWeMakeWorkTimeline
  },
  mixins: [
    assetsMixin
  ],
  data () {
    return {
      processSteps: [
        {
          id: 1,
          imgSrc: this.getImagePath('how-we-make-work/discover.svg'),
          title: 'Discover',
          description: 'We break down your project into iterations to cover all of the required functionality'
        },
        {
          id: 2,
          imgSrc: this.getImagePath('how-we-make-work/design.svg'),
          title: 'Design',
          description: 'We bring logic and structure, looking for the simplest and safest solutions that will minimize your costs'
        },
        {
          id: 3,
          imgSrc: this.getImagePath('how-we-make-work/develop.svg'),
          title: 'Develop',
          description: 'We write code to agreed standards, it’s pair programmed and continuously integrated'
        },
        {
          id: 4,
          imgSrc: this.getImagePath('how-we-make-work/test_optimize.svg'),
          title: 'Test & Optimize',
          description: 'Project goes through a series of tests, to determine if the developed features works'
        },
        {
          id: 4,
          imgSrc: this.getImagePath('how-we-make-work/release.svg'),
          title: 'Release',
          description: 'We will constantly improve and refactor to create stable well-performing system'
        }
      ]
    }
  }
}
</script>
