<template>
    <div class="content full-height first-section" id="secVideo">
        <div class="hero-wrap">
            <simple-image-component
                :translate-y="300"
                :data-bg="getImagePath('bg/main-bg.jpg')"
            />

            <video-title-section/>

            <scroll-down-button/>

            <fog-component/>
        </div>
    </div>
</template>

<script>

import VideoTitleSection from './video-section/VideoTitleSection'
import ScrollDownButton from './video-section/ScrollDownButton'
import SimpleImageComponent from './video-section/SimpleImageComponent'
import assetsMixin from '../../../mixins/assetsMixin'
import FogComponent from './video-section/FogComponent'

export default {
  name: 'VideoSection',
  components: {
    FogComponent,
    SimpleImageComponent,
    ScrollDownButton,
    VideoTitleSection
  },
  mixins: [
    assetsMixin
  ]
}
</script>
