<template>
    <div class="testimon-slider-holder">
        <div class="testimon-slider">
            <testimonial-item
                v-for="testimonialItem in testimonialsList"
                :key="testimonialItem.title"
                :title="testimonialItem.title"
                :testimonial="testimonialItem.testimonial"
            />
        </div>
        <div class="customNavigation">
            <span class="prev-slide transition"><i class="fa fa-angle-left"></i></span>
            <span class="next-slide transition"><i class="fa fa-angle-right"></i></span>
        </div>
    </div>
</template>

<script>
import TestimonialItem from './TestimonialItem'

export default {
  name: 'TestimonialsList',
  components: {
    TestimonialItem
  },
  data () {
    return {
      testimonialsList: [
        {
          title: 'Danial Hanno',
          testimonial: 'I had the opportunity to collaborate with Aplire on a challenging software' +
              ' development project. Their team was highly proficient, knowledgeable, and prompt in their' +
              ' responses throughout the entire project. They furnished a top-notch product that surpassed our' +
              ' expectations, and they were always accessible to address any queries or apprehensions we had.' +
              ' I would strongly recommend this company to anyone seeking a dependable and proficient technology ' +
              'collaborator.'
        },
        {
          title: 'Gernot Gotthold',
          testimonial: 'Collaborating with Aplire on our website redesign project was a delightful experience.' +
              ' They were able to transform our vision into a tangible reality by creating a visually appealing and' +
              ' user-friendly website that has garnered positive feedback from our customers. Their team was always' +
              ' accessible to address any queries we had and offered valuable insights and recommendations throughout' +
              ' the project. I would certainly collaborate with them again and strongly endorse their services to' +
              ' anyone seeking to enhance their online visibility.'
        },
        {
          title: 'Gunther Silvester',
          testimonial: 'For numerous years, I have been collaborating with owners of Aplire, and they have unfailingly' +
              ' furnished exceptional service and assistance. They are proficient in their domain and are constantly' +
              ' updated with the latest technological developments and trends. They have aided us in streamlining' +
              ' our operations and enhancing our productivity, and I would strongly endorse them to anyone seeking' +
              ' a dependable and trustworthy technology collaborator.'
        },

        {
          title: 'Wendelin Rian',
          testimonial: 'It\'s difficult to overstate how impressed we are with Aplire. Their team possesses' +
              ' extensive knowledge, expertise, and is always willing to go the extra mile to guarantee their' +
              ' clients\' satisfaction. They assisted us in creating a customized software solution that ' +
              'revolutionized our business, resulting in time and cost savings, as well as increased productivity.' +
              ' They have earned our trust as a reliable technology partner, and we are excited to continue working' +
              ' with them in the future.'
        }
      ]
    }
  }
}
</script>
