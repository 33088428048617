<template>
    <div class="content" id="secContacts">

        <section-number-component
            wrapper-class="sect-subtitle right-align-dec"
            :section-number=sectionNumber
            translate-y="200"
        />

        <section>
            <div class="container">
                <div class="row">
                    <div class="col-md-8">
                        <contact-us-description/>

                        <contact-us-form/>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

import SectionNumberComponent from '../../../components/common/SectionNumberComponent'
import ContactUsDescription from './contact-us-section/ContactUsDescription'
import ContactUsForm from './contact-us-section/ContactUsForm'

export default {
  name: 'ContactUsSection',
  props: {
    sectionNumber: {
      required: false,
      type: String,
      default: '00'
    }
  },
  components: {
    ContactUsForm,
    ContactUsDescription,
    SectionNumberComponent
  }
}
</script>
