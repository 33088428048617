<template>
    <div class="nav-holder">
        <nav class="scroll-nav">
            <ul>
                <li>
                    <a
                        class="scroll-link act-link"
                        href="#secVideo"
                        data-bgtex="Home">
                        <span>Home</span>
                    </a>
                </li>
                <li>
                    <a
                        class="scroll-link"
                        href="#secAboutUs"
                        data-bgtex="about us">
                        <span>About</span>
                    </a>
                </li>
                <li>
                    <a class="scroll-link"
                       href="#secServices"
                       data-bgtex="Our services">
                        <span>Services</span>
                    </a>
                </li>
                <li>
                    <a
                        class="scroll-link"
                        href="#secSkills"
                        data-bgtex="Our skills">
                        <span>Skills</span>
                    </a>
                </li>
                <li>
                    <a
                        class="scroll-link"
                        href="#secOurWork"
                       data-bgtex="Our Work">
                        <span>Our Work</span>
                    </a>
                </li>
                <li>
                    <a class="scroll-link"
                       href="#secContacts"
                       data-bgtex="contacts">
                        <span>Contacts</span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
  name: 'NavigationComponent'
}
</script>
