import _get from 'lodash/get'

export default {
  getErrs: err => _get(err, 'response.data.errors', []),
  showLoader: function (duration = 500) {
    // eslint-disable-next-line no-undef
    $('.loader').fadeIn(duration, function () {
      // eslint-disable-next-line no-undef
      $('#main').animate({ opacity: '0' })
    })
  },
  hideLoader: function (duration = 500) {
    // eslint-disable-next-line no-undef
    $('.loader').fadeOut(duration, function () {
      // eslint-disable-next-line no-undef
      $('#main').animate({ opacity: '1' })
    })
  }
}
