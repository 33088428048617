<template>
    <div class="contact-form-holder">
        <div id="contact-form">
            <form @submit.prevent="recaptchaSubmit(form, submit)">

                <label :class="{
                    'form-label': true,
                    'required': true,
                    'error': errorFields.includes('name')}">
                    <span class="form-label__text">Enter name:</span>
                    <span v-if="submitErrors.name" class="form-label__error">
                        {{ submitErrors.name.join(', ') }}
                    </span>
                    <input
                        v-model="form.name"
                        maxlength="100"
                        type="text"
                        required
                      />
                </label>

                <label :class="{
                    'form-label': true,
                    'required': true,
                    'error': errorFields.includes('email')}">
                    <span class="form-label__text">Enter E-mail address:</span>
                    <span v-if="submitErrors.email" class="form-label__error">
                        {{ submitErrors.email.join(', ') }}
                    </span>
                    <input
                        v-model="form.email"
                        maxlength="100"
                        type="email"
                        required
                    />
                </label>

                <label :class="{
                    'form-label': true,
                    'required': false,
                    'error': errorFields.includes('message')}">
                    <span class="form-label__text">Enter message:</span>
                    <span v-if="submitErrors.message" class="form-label__error">
                        {{ submitErrors.message.join(', ') }}
                    </span>
                    <textarea
                        v-model="form.message"
                        maxlength="200"
                    ></textarea>
                </label>

                <button
                    :disabled="isSubmit"
                    type="submit"
                    id="submit"
                    class="submit-btn anim-button">
                    Send Message
                </button>
            </form>
        </div>
    </div>
</template>

<script>

import submitMixin from './../../../../mixins/forms/submitMixin'
import errorMixin from './../../../../mixins/forms/errorMixin'
import recaptchaMixin from './../../../../mixins/forms/recaptchaMixin'
import api from './../../../../helpers/api'

export default {
  name: 'ContactUsForm',
  mixins: [
    submitMixin,
    errorMixin,
    recaptchaMixin
  ],
  data () {
    return {
      form: {
        name: '',
        email: '',
        message: ''
      }
    }
  },
  methods: {
    async submit () {
      const loaderFadeDuration = 300

      this.$utils.showLoader(loaderFadeDuration)
      this.disableSubmit()

      await api.post('/feedback-requests', this.form)
        .then(() => {
          const that = this

          this.$utils.hideLoader(loaderFadeDuration)
          this.enableSubmit()
          this.resetForm()

          this.$emit('feedbackRequestCreated')

          setTimeout(function () {
            const successMessage = 'Thanks for you request, we\'ll contact with you as soon as possible!'
            that.$toast.success(successMessage)
          }, loaderFadeDuration)
        })
        .catch((error) => {
          this.$utils.hideLoader(loaderFadeDuration)
          this.enableSubmit()
          this.catchError(error)
        })
    },
    resetForm () {
      this.form = {
        name: '',
        email: '',
        message: ''
      }
    }
  }
}
</script>
