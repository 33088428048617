export default {
  data () {
    return {
      socialLinks: [
        {
          id: 1,
          title: 'Linkedin',
          icon: 'fa-linkedin',
          link: 'https://www.linkedin.com/company/amplire'
        }
      ]
    }
  }
}
