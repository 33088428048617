<template>
    <a
        href="#secAboutUs"
        aria-label="Scroll down"
        class="hero-scroll-link hero-scroll-link--pulse custom-scroll-link"
        data-top-bottom="transform: translateY(50px);"
        data-bottom-top="transform: translateY(-50px);">
        <i class="fa fa-angle-down"></i>
    </a>
</template>

<script>
export default {
  name: 'ScrollDownButton'
}
</script>
