import NotFoundPage from './../pages/statics/NotFoundPage'
import EmptyLayout from './../layouts/EmptyLayout'

export default [
  {
    path: '/:catchAll(.*)',
    component: NotFoundPage,
    redirect: '/404'
  },
  {
    path: '/404',
    name: '404',
    component: NotFoundPage,
    meta: {
      layout: EmptyLayout,
      title: 'Page Not Found'
    }
  }
]
