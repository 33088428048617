<template>
    <div class="content" id="secServices">
        <section>
            <section-number-component
                wrapper-class="sect-subtitle right-align-dec"
                :section-number=sectionNumber
                translate-y="200"
            />

            <div class="container">

                <what-we-do-description/>

                <what-we-do-list/>
            </div>
        </section>
    </div>
</template>

<script>

import SectionNumberComponent from './../../../components/common/SectionNumberComponent'
import WhatWeDoDescription from './what-we-do-section/WhatWeDoDescription'
import WhatWeDoList from './what-we-do-section/WhatWeDoList'

export default {
  name: 'WhatWeDoSection',
  props: {
    sectionNumber: {
      required: false,
      type: String,
      default: '00'
    }
  },
  components: {
    WhatWeDoList,
    WhatWeDoDescription,
    SectionNumberComponent
  }
}
</script>
