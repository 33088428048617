<template>
    <div class="contact-details">
        <div class="row">
            <div class="col-md-5">
                <h3 class="bold-title">Contact details&nbsp;: </h3>
            </div>
            <div class="col-md-5">
                <h4>Find Us On : </h4>
                <ul>
                    <li
                        v-for="socialLink in socialLinks"
                        :key="socialLink.id">
                        <a
                            :href="socialLink.link"
                            target="_blank">
                            {{ socialLink.title }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

import socialLinksMixin from '../../../../mixins/socialLinksMixin'

export default {
  name: 'ContactUsDescription',
  mixins: [
    socialLinksMixin
  ]
}
</script>
