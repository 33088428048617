export default {
  data () {
    return {
      isSubmit: false
    }
  },
  methods: {
    disableSubmit () {
      this.isSubmit = true
    },
    enableSubmit () {
      this.isSubmit = false
    }
  }
}
