<template>
    <a
        :href="detailItemUrl"
        class="serv-item">
        <div class="serv-item-inner">
            <img
                :src="imageSrc"
                :alt="title">
            <div class="ser-title">
                <h3>{{ title }}</h3>
            </div>
        </div>
    </a>

</template>

<script>
export default {
  name: 'WhatWeDoPreviewItem',
  props: {
    title: {
      required: true,
      type: String
    },
    id: {
      required: true,
      type: String
    },
    imageSrc: {
      required: true,
      type: String
    }
  },
  computed: {
    detailItemUrl () {
      return '#' + this.id
    }
  }
}
</script>
