<template>
    <svg width="1142" height="114" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M1086.91.586c.79.78.79 2.047 0 2.828l-9.1 9.106c15.65.142 29.12 1.081 39.58 4.746 5.81 2.036 10.79 4.942 14.73 9.092 3.94 4.158 6.72 9.444 8.31 16.059 3.15 13.137.96 26.681-8.65 36.92-9.56 10.19-26.05 16.667-50.59 16.667-3.8 0-13.72.018-27.15.043h-.02c-48.94.09-144.436.266-160.07-.044l.049-5h187.191c23.76 0 38.68-6.274 46.95-15.087 8.22-8.763 10.28-20.47 7.43-32.333-1.41-5.885-3.82-10.35-7.08-13.784-3.27-3.444-7.5-5.976-12.75-7.815-9.84-3.444-22.88-4.356-38.9-4.47l10.07 10.072c.79.78.79 2.047 0 2.828a1.99 1.99 0 0 1-2.82 0l-13.5-13.5a1.984 1.984 0 0 1 0-2.828l13.5-13.5a1.99 1.99 0 0 1 2.82 0ZM14 91h874v5H14v-5Z"
              fill="#000"></path>
        <g>
            <circle cx="16" cy="93" r="10" fill="#000" stroke="#fff"
                    stroke-width="3"></circle>
        </g>
        <g>
            <circle cx="212" cy="93" r="10" fill="#000" stroke="#fff"
                    stroke-width="3"></circle>
        </g>
        <g>
            <circle cx="412" cy="93" r="10" fill="#000" stroke="#fff"
                    stroke-width="3"></circle>
        </g>
        <g>
            <circle cx="612" cy="93" r="10" fill="#000" stroke="#fff"
                    stroke-width="3"></circle>
        </g>
        <path
            d="M903.283 71.4384C902.403 69.4621 899.597 69.4621 898.717 71.4384L894.181 81.6141L883.101 82.7835C880.95 83.0106 880.083 85.6783 881.69 87.1268L889.966 94.5851L887.655 105.484C887.206 107.6 889.475 109.249 891.349 108.168L901 102.602L910.651 108.168C912.525 109.249 914.794 107.6 914.345 105.484L912.034 94.5851L920.31 87.1268C921.917 85.6783 921.05 83.0106 918.899 82.7835L907.819 81.6141L903.283 71.4384ZM894.063 81.8775C894.063 81.8773 894.064 81.8771 894.064 81.8769L894.063 81.8775ZM901.25 102.458C901.249 102.458 901.249 102.458 901.249 102.458L901.25 102.458Z"
            fill="#000" stroke="black" stroke-width="3"></path>
        <path
            d="M900.087 72.0491C900.439 71.2586 901.561 71.2586 901.913 72.0491L906.567 82.4882C906.712 82.8142 907.02 83.0381 907.375 83.0755L918.741 84.2752C919.602 84.366 919.949 85.4331 919.306 86.0125L910.815 93.6639C910.55 93.9028 910.433 94.265 910.507 94.6142L912.878 105.795C913.058 106.641 912.15 107.301 911.4 106.868L901.5 101.158C901.19 100.98 900.81 100.98 900.5 101.158L890.6 106.868C889.85 107.301 888.942 106.641 889.122 105.795L891.493 94.6142C891.567 94.265 891.45 93.9028 891.185 93.6639L882.694 86.0125C882.051 85.4331 882.398 84.366 883.259 84.2752L894.625 83.0755C894.98 83.0381 895.288 82.8142 895.433 82.4882L900.087 72.0491Z"
            fill="#000"></path>
        <defs>
            <linearGradient id="a" x1="271.948" y1="214.112" x2="347.807" y2="-157.36"
                            gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#0E4ED7"></stop>
                <stop offset="1" stop-color="#F94743"></stop>
            </linearGradient>
            <filter id="b" x="0" y="0" width="80" height="80" filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                               result="hardAlpha"></feColorMatrix>
                <feOffset dx="8" dy="8"></feOffset>
                <feGaussianBlur stdDeviation="12"></feGaussianBlur>
                <feColorMatrix
                    values="0 0 0 0 0.256493 0 0 0 0 0.2739 0 0 0 0 0.691667 0 0 0 0.86 0"></feColorMatrix>
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_6589_38128"></feBlend>
                <feBlend in="SourceGraphic" in2="effect1_dropShadow_6589_38128" result="shape"></feBlend>
            </filter>
        </defs>
    </svg>
</template>

<script>

export default {
  name: 'HowWeMakeWorkTimeline'
}
</script>
