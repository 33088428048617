export default {
  methods: {
    async recaptchaSubmit (form, submitMethod) {
      await this.$recaptchaLoaded()
      const reCaptchaAction = 'login'

      form.recaptchaToken = await this.$recaptcha(reCaptchaAction)
      submitMethod()
    }
  }
}
