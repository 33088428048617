<template>
    <header>
        <div class="header-inner">
            <logo-component/>
            <mobile-nav-button/>
            <navigation-component/>
        </div>
    </header>
</template>

<script>

import NavigationComponent from './../../../layouts/default-partials/header/NavigationComponent'
import LogoComponent from './../../../layouts/default-partials/header/LogoComponent'
import MobileNavButton from './../../../layouts/default-partials/header/MobileNavButton'

export default {
  name: 'HeaderComponent',
  components: {
    MobileNavButton,
    LogoComponent,
    NavigationComponent
  }
}
</script>
