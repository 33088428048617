<template>
    <div id="rootBody">
        <div id="wrapper">
            <div class="content-holder">

                <video-section/>

                <about-us-section
                    section-number="01"
                />

                <interesting-facts-section
                    section-number="02"
                />

                <what-we-do-section
                    section-number="03"
                />

                <our-skills-section
                    section-number="04"
                />

                <how-we-make-work-section
                    section-number="05"
                />

                <testimonials-section
                    section-number="06"
                />

                <contact-us-section
                    section-number="07"
                />

                <content-footer-component/>
            </div>

        </div>
    </div>
</template>

<script>

import VideoSection from './parials/VideoSection'
import AboutUsSection from './parials/AboutUsSection'
import InterestingFactsSection from './parials/InterestingFactsSection'
import OurSkillsSection from './parials/OurSkillsSection'
import WhatWeDoSection from './parials/WhatWeDoSection'
import ContactUsSection from './parials/ContactUsSection'
import TestimonialsSection from './parials/TestimonialsSection'
import ContentFooterComponent from '../../layouts/default-partials/footer/ContentFooterComponent'
import HowWeMakeWorkSection from './parials/HowWeMakeWorkSection'

export default {
  name: 'HomePage',
  components: {
    HowWeMakeWorkSection,
    TestimonialsSection,
    ContentFooterComponent,
    ContactUsSection,
    WhatWeDoSection,
    OurSkillsSection,
    InterestingFactsSection,
    AboutUsSection,
    VideoSection
  }
}
</script>
