import HomePage from './../pages/home/HomePage'

export default [{
  path: '/',
  name: 'home',
  component: HomePage,
  meta: {
    title: '',
    description: 'Amplify your business with our IT solutions. Amplire offers you the opportunity to become one of the most digitally successful companies in your industry in quantum leaps'
  }
}]
