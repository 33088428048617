import axios from 'axios'
import meta from './meta'

export const api = axios.create({
  baseURL: process.env.MIX_API_URL,
  headers: {
    'X-CSRF-TOKEN': meta.getCsrfToken(),
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

export default {
  delete (url) {
    return api.delete(url)
  },
  post (url, additional = {}) {
    return api.post(url, JSON.stringify(additional))
  },
  put (url, additional = {}) {
    return api.put(url, JSON.stringify(additional))
  },
  get (url) {
    return api.get(url)
  }
}
