<template>
    <div class="item">
        <h3>
            {{ title }}
        </h3>
        <p>"{{ testimonial }}"</p>
    </div>
</template>

<script>
export default {
  name: 'TestimonialItem',
  props: {
    title: {
      required: true,
      type: String
    },
    testimonial: {
      required: false,
      type: String
    }
  }
}
</script>
