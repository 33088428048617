<template>
    <footer class="fixed-footer">
        <socials-component/>

        <!-- Header  title -->
        <div class="footer-title">
            <h2>Home</h2>
        </div>
        <!-- Header  title  end-->
    </footer>
</template>

<script>

import SocialsComponent from './../../../layouts/default-partials/footer/default-footer/SocialsComponent'

export default {
  name: 'FooterComponent',
  components: {
    SocialsComponent
  }
}
</script>
