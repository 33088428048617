<template>
    <div class="parallax-inner">
        <div
            :class="wrapperClass"
            :data-bg="dataBg"
            :data-top-bottom="dataTopBottom"
            :data-bottom-top="dataBottomTop">
        </div>
        <div class="overlay"></div>
    </div>
</template>

<script>
export default {
  name: 'ParallaxImageComponent',
  props: {
    wrapperClass: {
      required: false,
      type: String
    },
    dataBg: {
      required: true,
      type: String
    },
    translateY: {
      required: true,
      type: [
        Number,
        String
      ]
    }
  },
  computed: {
    dataTopBottom () {
      return 'transform: translateY(' + this.translateY + 'px);'
    },
    dataBottomTop () {
      return 'transform: translateY(-' + this.translateY + 'px);'
    }
  }
}
</script>
